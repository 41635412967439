import { walletConnect } from "@wagmi/connectors";
import {
  signMessage,
  watchAccount,
  getAccount,
  writeContract,
  readContract,
  createConfig,
  http,
  simulateContract,
  reconnect,
  getBalance,
  injected,
  fallback,
  switchChain,
  deserialize,
  disconnect,
  waitForTransactionReceipt,
} from "@wagmi/core";
import {
  base,
  mainnet,
  moonbeam,
  polygon,
  polygonAmoy,
  polygonMumbai,
} from "@wagmi/core/chains";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi";
import { formatUnits } from "viem";

const defaultChain = window.voidgeConfig.polygonTestnet ? polygonAmoy : polygon;

// Wallet Connect ProjectID
const projectId = window.voidgeConfig.walletConnectProjectId;

const chains_aux = [mainnet, polygon, moonbeam, base];
const transports_aux = {
  [mainnet.id]: fallback([
    http(
      "https://eth-mainnet.g.alchemy.com/nft/v2/ZpB47DLZfW-sJo36-7v03AGvndBiCNgt"
    ),
    http(),
  ]),
  [polygon.id]: fallback([http()]),
  [moonbeam.id]: fallback([http()]),
  [base.id]: fallback([http()]),
};

if (window.voidgeConfig.polygonTestnet) {
  chains_aux.push(polygonAmoy);

  transports_aux[polygonAmoy.id] = http();
}

// Wagmi provider
const config = createConfig({
  chains: chains_aux,
  transports: transports_aux,
});

// WEB3 MODAL
const metadata = {
  name: "Voidge Modal",
  description: "Modal to connect wallet.",
  url: "https://voidge.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = config.chains;

const config_wagmi = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

reconnect(config);

const web3modal = createWeb3Modal({
  wagmiConfig: config_wagmi,
  projectId,
  enableAnalytics: false,
});

function convertBigIntToString(obj) {
  if (typeof obj === "bigint") {
    return obj.toString();
  } else if (Array.isArray(obj)) {
    return obj.map(convertBigIntToString);
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = convertBigIntToString(obj[key]);
      return acc;
    }, {});
  } else {
    return obj;
  }
}

export class UnityWeb3 {
  constructor(unityInstance) {
    this.unityInstance = unityInstance;
    this.cachedContractAbi = {};

    window.voidgeFunctions = {
      gameStart: () => {
        if (this.gameStateCallback) {
          this.gameStateCallback();
        }
      },
      openConnectModal: () => {
        if (getAccount(config).address) {
          this.connectWallet(getAccount(config));
        } else {
          web3modal.open();
        }
      },
      signLoginMessage: async () => {
        try {
          const { connector } = getAccount(config);
          const signature = await signMessage(config, {
            connector,
            message: window.voidgeConfig.loginMessage,
          });
          this.unityInstance.SendMessage(
            window.voidgeConfig.proxyGameObject,
            "LoginMessageSigned",
            signature
          );
          if (window.voidgeConfig.debug)
            console.log(
              `"${
                window.voidgeConfig.proxyGameObject
              }":LoginMessageSigned(${signature.slice(0, 5)}...)`
            );
        } catch (err) {
          this.unityInstance.SendMessage(
            window.voidgeConfig.proxyGameObject,
            "LoginMessageReject"
          );
          if (window.voidgeConfig.debug)
            console.log(
              `"${window.voidgeConfig.proxyGameObject}":LoginMessageReject()`
            );
        }
      },
      getConnectedAddress: () => {
        return getAccount(config).address;
      },
      writeContract: async (contractAddress, functionName, args, nonce) => {
        const writeConfig = {
          address: contractAddress,
          functionName: functionName,
          args: JSON.parse(args),
        };

        this.voidgeWriteContract(writeConfig, nonce);
      },
      writeContractPayable: async (
        payableAmount,
        contractAddress,
        functionName,
        args,
        nonce
      ) => {
        const writeConfig = {
          address: contractAddress,
          functionName: functionName,
          args: JSON.parse(args),
          value: BigInt(payableAmount),
        };

        this.voidgeWriteContract(writeConfig, nonce);
      },
      readContract: async (contractAddress, functionName, args, nonce) => {
        let abiJson = await this.getContractAbi(contractAddress);

        let data;
        try {
          data = await readContract(config, {
            address: contractAddress,
            abi: abiJson,
            functionName: functionName,
            args: JSON.parse(args),
          });
        } catch (err) {
          console.log(err);
        }

        const response = {
          data: { raw: convertBigIntToString(data) },
          nonce: nonce,
        };
        const responseJson = JSON.stringify(response);

        this.unityInstance.SendMessage(
          window.voidgeConfig.proxyGameObject,
          "ReadContractData",
          responseJson
        );
        if (window.voidgeConfig.debug)
          console.log(
            `"${window.voidgeConfig.proxyGameObject}":ReadContractData(${responseJson})`
          );
      },
      updateBalance: async () => {
        if (!getAccount(config).address) return;

        const balance = await getBalance(config, {
          address: getAccount(config).address,
          chainId: defaultChain.id,
        });

        this.unityInstance.SendMessage(
          window.voidgeConfig.proxyGameObject,
          "OnUpdateBalance",
          "0x" + balance.value.toString(16)
        );
        if (window.voidgeConfig.debug)
          console.log(
            `"${window.voidgeConfig.proxyGameObject}":OnUpdateBalance(${balance.decimals})`
          );
      },
      updateTokenBalance: async (tokenAddress) => {
        if (!getAccount(config).address) return;

        const balance = await getBalance(config, {
          address: getAccount(config).address,
          chainId: defaultChain.id,
        });

        this.unityInstance.SendMessage(
          window.voidgeConfig.proxyGameObject,
          "OnUpdateTokenBalance",
          tokenAddress + "|" + ("0x" + balance.value.toString(16))
        );
        if (window.voidgeConfig.debug)
          console.log(
            `"${window.voidgeConfig.proxyGameObject}":OnUpdateTokenBalance(${
              tokenAddress + "|" + ("0x" + balance.value.toString(16))
            })`
          );

        return balance.decimals;
      },
      switchChain: async (chainId) => {
        console.log("Switching chain to: " + chainId);
        console.log(getAccount(config).chain);

        if (getAccount(config).chain == chainId) return;

        if (!getAccount(config).address) {
          web3modal.open();
        }

        await switchChain(config, { chainId: chainId });
      },
    };
    watchAccount(config, {
      onChange: function (account) {
        if (!account) return;
        this.connectWallet(account);
      }.bind(this),
    });

    web3modal.subscribeState((newState) => {
      this.sendModalState(newState.open ? 1 : 0);
    });

    this.unityInstance.SendMessage(
      window.voidgeConfig.proxyGameObject,
      "VoidgeWeb3Initialized"
    );
  }

  async connectWallet(account) {
    if (account.address) {
      this.unityInstance.SendMessage(
        window.voidgeConfig.proxyGameObject,
        "OnConnect",
        ""
      );
      if (window.voidgeConfig.debug)
        console.log(`"${window.voidgeConfig.proxyGameObject}":OnConnect("")`);
    } else {
      this.unityInstance.SendMessage(
        window.voidgeConfig.proxyGameObject,
        "OnDisconnect"
      );
      if (window.voidgeConfig.debug) {
        console.log(`"${window.voidgeConfig.proxyGameObject}":OnDisconnect()`);
      }
    }
  }

  async voidgeWriteContract(configData, nonce) {
    let config_contract = {};

    try {
      // Here we'll indentify to what chain to change.
      if (getAccount(config).chain != defaultChain.id) {
        await switchChain(config, { chainId: defaultChain.id });
      }

      // Getting contract ABI
      configData.abi = await this.getContractAbi(configData.address);

      // Prepare write contract
      const { request } = await simulateContract(config, configData);
      config_contract = request;
    } catch (e) {
      const response = {
        data: e,
        nonce: nonce,
      };
      const responseJson = JSON.stringify(response);

      this.unityInstance.SendMessage(
        window.voidgeConfig.proxyGameObject,
        "TransactionFailed",
        responseJson
      );
      if (window.voidgeConfig.debug)
        console.log(
          `"${window.voidgeConfig.proxyGameObject}":TransactionFailed(${responseJson})`
        );
      return;
    }

    this.unityInstance.SendMessage(
      window.voidgeConfig.proxyGameObject,
      "TransactionStart"
    );
    if (window.voidgeConfig.debug)
      console.log(
        `"${window.voidgeConfig.proxyGameObject}":TransactionStart()`
      );

    let hash = null;
    try {
      hash = await writeContract(config, config_contract);

      this.unityInstance.SendMessage(
        window.voidgeConfig.proxyGameObject,
        "TransactionPending",
        hash
      );
      if (window.voidgeConfig.debug)
        console.log(
          `"${window.voidgeConfig.proxyGameObject}":TransactionPending(${hash})`
        );
    } catch (err) {
      console.log(err);

      const response = {
        nonce: nonce,
      };
      const responseJson = JSON.stringify(response);

      this.unityInstance.SendMessage(
        window.voidgeConfig.proxyGameObject,
        "TransactionDenied",
        responseJson
      );
      if (window.voidgeConfig.debug)
        console.log(
          `"${window.voidgeConfig.proxyGameObject}":TransactionDenied(${responseJson})`
        );
      return;
    }

    let transactionReceipt = await waitForTransactionReceipt(config, {
      hash: hash,
      confirmations: window.voidgeConfig.txConfirmations,
    });

    if (transactionReceipt.status == "success") {
      const response = {
        data: JSON.stringify(convertBigIntToString(transactionReceipt)),
        nonce: nonce,
      };
      const responseJson = JSON.stringify(response);

      this.unityInstance.SendMessage(
        window.voidgeConfig.proxyGameObject,
        "TransactionSucceeded",
        responseJson
      );
      if (window.voidgeConfig.debug)
        console.log(
          `"${window.voidgeConfig.proxyGameObject}":TransactionSucceeded(${responseJson})`
        );
    } else {
      const response = {
        data: JSON.stringify(convertBigIntToString(result)),
        nonce: nonce,
      };
      const responseJson = JSON.stringify(response);

      this.unityInstance.SendMessage(
        window.voidgeConfig.proxyGameObject,
        "TransactionFailed",
        responseJson
      );
      if (window.voidgeConfig.debug)
        console.log(
          `"${window.voidgeConfig.proxyGameObject}":TransactionFailed(${responseJson})`
        );
    }
  }

  async getContractAbi(contractAddress) {
    contractAddress = contractAddress.toLowerCase();

    if (!this.cachedContractAbi[contractAddress]) {
      let abiJsonPath = `${window.voidgeConfig.contractsAbiRepository}/${contractAddress}.json`;
      let abiJsonResponse = await fetch(abiJsonPath);
      this.cachedContractAbi[contractAddress] = await abiJsonResponse.json();

      if (window.voidgeConfig.debug)
        console.log(`Putting ${contractAddress} ABI in cache`);
    }
    return this.cachedContractAbi[contractAddress];
  }

  sendModalState(state) {
    this.unityInstance.SendMessage(
      window.voidgeConfig.proxyGameObject,
      "ChangeModalState",
      state
    );
    if (window.voidgeConfig.debug)
      console.log(
        `"${window.voidgeConfig.proxyGameObject}":ChangeModalState(${state})`
      );
  }

  watchGameState(gameStateCallback) {
    this.gameStateCallback = gameStateCallback;
  }
}
